<template>
  <div class="container">
    <div>
      <el-card class="box-card" shadow="never">
        <div class="card-content">
          <el-form :inline="true" :model="searchForm" label-width="100px" label-position="right" ref="searchFormRef">
            <el-form-item label="商户:">
              <el-select v-model="searchForm.merchant_id" placeholder="请输入商户号或昵称" filterable clearable>
                <el-option v-for="option in optionsMerchant" :key="option.id"
                  :label="option.no + '-' + option.merchant_alias" :value="option.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设备类型：">
              <el-cascader v-model="searchForm.device_type_id" clearable :options="deviceOptions" :props="props"
                @change="(val) => { searchForm.device_type_id = val ? val[1] : '' }" />
            </el-form-item>
            <el-form-item label="设备SN：">
              <el-input v-model="searchForm.device_sn" placeholder="支持第三方设备号" style="width: 220px;" />
            </el-form-item>
            <el-form-item label="启用状态：">
              <el-select v-model="searchForm.status" filterable placeholder="请选择" clearable>
                <el-option :value="1" label="已启用" />
                <el-option :value="0" label="未启用" />
              </el-select>
            </el-form-item>
            <el-form-item label="激活码：">
              <el-input v-model="searchForm.active_code" placeholder="请输入激活码" style="width: 220px;" />
            </el-form-item>
            <el-form-item label="终端ID：">
              <el-input v-model="searchForm.id" placeholder="请输入终端ID" style="width: 220px;" />
            </el-form-item>
            <el-form-item style="margin-left: 20px">
              <el-button type="primary" @click="getTerminalList(searchForm, '1')">查询</el-button>
              <el-button plain @click="onClickResetSearchField">重置</el-button>
              <el-button type="primary" plain @click="onClickAddTerminal" :icon="Plus">新增终端</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-card>
    </div>
    <div class="main">
      <el-card shadow="never" class="card-border" v-loading="loading">
        <el-table :data="tableData" stripe style="width: 100%" size="small">
          <el-table-column prop="id" label="终端ID" width="80" />
          <el-table-column prop="name" label="名称" width="120" show-overflow-tooltip />
          <el-table-column prop="active_code" label="激活码" width="80" />
          <el-table-column label="设备类型" width="120" show-overflow-tooltip>
            <template #default="scope">
              <text v-if="scope.row.device == null"></text>
              <text v-else>{{ scope.row.device?.device_category?.name || '-' }}/{{ scope.row.device?.device_type?.name || '-' }}</text>
            </template>
          </el-table-column>
          <el-table-column prop="store.name" label="门店" show-overflow-tooltip />
          <el-table-column prop="merchant.merchant_alias" label="商户简称" show-overflow-tooltip />
          <el-table-column prop="device.serial_number" label="收银设备" show-overflow-tooltip />
          <el-table-column prop="merchant.no" label="商户号" width="80" show-overflow-tooltip />
          <el-table-column prop="status" label="启用状态" width="80">
            <template #default="{ row }">
              <el-tag type="success" v-if="row.status">已启用</el-tag>
              <el-tag type="warning" v-else>未启用</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="operator" label="操作人" width="80" show-overflow-tooltip />
          <el-table-column prop="actived_at" label="激活时间" width="100" show-overflow-tooltip />
          <el-table-column prop="created_at" label="创建时间" width="100" show-overflow-tooltip />
          <el-table-column prop="updated_at" label="更新时间" width="100" show-overflow-tooltip />
          <el-table-column label="操作" width="120" fixed="right">
            <template #default="scope">
              <div style="display: flex; flex-direction: row;">
                <el-button :icon="Search" circle @click="onClickShowTerminal(scope.row)" />
                <el-button type="primary" :icon="Edit" circle @click="onClickEditTerminal(scope.row)" />
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination :page-size="pagination.perPage" :pager-count="7" layout="total,prev, pager, next, jumper, slot"
            :total="pagination.total" @current-change="handleSizeChange" :background="true"
            :hide-on-single-page="false">
          </el-pagination>
        </div>
      </el-card>
      <el-dialog v-model="showTerminalDetail" title="终端详情" center width="55%" destroy-on-close>
        <template #default>
          <el-card class="card-border" shadow="never">
            <div class="card-content">
              <div class="detail-rows">
                <div class="detail-item" v-for="(item, index) in detailParams" :key="index">
                  <el-tag class="label">
                    {{ item.label }}
                  </el-tag>
                  <div v-if="item.label != '启用状态'" class="value">
                    {{ item.value }}
                  </div>
                  <div v-else class="value">
                    <el-tag type="success" v-if="item.value">已启用</el-tag>
                    <el-tag type="warning" v-else>未启用</el-tag>
                  </div>
                </div>
              </div>
            </div>
          </el-card>
          <el-card class="card-border" shadow="never"
            v-if="terminalDetails.device != null && terminalDetails.device.device_type_id == CONGMINFU_DEVICE_TYPE_ID && congmingPayParams">
            <div class="card-content">
              <div style="font-size: 18px;">
                内插参数
              </div>
              <div class="params-wrap">
                <div v-for="(item, key) in congmingPayParams" :key="key" class="third-party-device-params">
                  <span style="width: 100px">{{ item.label }}:</span>
                  <span style="flex: 1">{{ item.value }}</span>
                </div>
              </div>
            </div>
          </el-card>
          <el-card class="card-border" shadow="never"
            v-if="terminalDetails.device != null && terminalDetails.device.device_type_id == ALI_RUYI_DEVICE_TYPE_ID && ruyiParams">
            <div class="card-content">
              <div style="font-size: 18px;">
                如意参数
              </div>
              <div class="params-wrap">
                <div v-for="(item, key) in ruyiParams" :key="key" class="third-party-device-params">
                  <span style="width: 100px">{{ item.label }}:</span>
                  <span style="flex: 1">{{ item.value }}</span>
                </div>
              </div>
            </div>
          </el-card>
          <el-card class="card-border" shadow="never"
            v-if="terminalDetails.device != null && terminalDetails.device.device_type_id == BOSHIJIE_DEVICE_TYPE_ID && bsjParams">
            <div class="card-content">
              <div style="font-size: 18px;">
                博实结参数
              </div>
              <div class="params-wrap">
                <div v-for="(item, key) in bsjParams" :key="key" class="third-party-device-params">
                  <span style="width: 100px">{{ item.label }}:</span>
                  <span style="flex: 1">{{ item.value }}</span>
                </div>
              </div>
            </div>
          </el-card>
          <el-card v-if="terminalDetails.code">
            <div class="qrcode-list">
              <ScanQRCode v-if="terminalDetails.code.url_v2" :text-inputed="terminalDetails.code.url_v2"
                :readonly="true"></ScanQRCode>
              <ScanQRCode v-if="terminalDetails.code.url_h5" :xxl-ticket-sn="terminalDetails.xxl_ticket_sn"
                :text-inputed="terminalDetails.code.url_h5" :readonly="true" :is-h5="true"></ScanQRCode>
            </div>
          </el-card>
        </template>
      </el-dialog>
      <el-dialog :title="terminalFormIsEdit ? '编辑终端' : '新增终端'" width="60%" v-model="showTerminalForm" center
        @close="hideTerminalForm">
        <el-form ref="addTerminalRef" :model="terminalFormData" :rules="terminalRules" label-width="120px"
          label-position="right">
          <el-form-item prop="name">
            <template v-slot:label>
              <span class="required-label">终端名称:</span>
            </template>
            <el-input v-model="terminalFormData.name" placeholder="请输入" style="width: 220px;" />
          </el-form-item>
          <el-form-item label="绑定商户:" prop="merchant_id">
            <el-select v-model="terminalFormData.merchant_id" placeholder="请输入商户号或昵称" filterable clearable
              :disabled="disableMerchantInput">
              <el-option v-for="option in optionsMerchant" :key="option.id"
                :label="option.no + '-' + option.merchant_alias" :value="option.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="绑定门店:" prop="store_id">
            <el-select v-model="terminalFormData.store_id" placeholder="请选择门店" filterable clearable>
              <el-option v-for="option in optionsStore" :key="option.id" :label="option.id + '-' + option.name"
                :value="option.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="收银设备:" :inline="true" style="overflow: hidden;">
            <div style="display: flex;flex-direction: column;gap: 5px;">
              <div style="display: flex;gap: 10px;justify-content: flex-start;align-items: center">
                <el-select
                  v-if="(isDirectAddDevice && !terminalFormIsEdit) || (!isDirectAddDevice && terminalFormIsEdit)"
                  v-model="terminalFormData.device_id" placeholder="请选择" filterable clearable>
                  <el-option v-for="option in optionsDevice" :key="option.id" :label="option.label"
                    :value="option.id"></el-option>
                </el-select>
                <el-cascader
                  v-if="(!isDirectAddDevice && !terminalFormIsEdit) || (isDirectAddDevice && terminalFormIsEdit)"
                  v-model="terminalFormData.new_device_type_id" :options="deviceOptions" :props="props"
                  @change="onChangeDeviceType" />
                <el-button @click="switchDeviceAddMode" size="default" type="plain"
                  :icon="isDirectAddDevice ? Plus : Menu">
                  {{ isDirectAddDevice ? '直接添加' : '选择已有' }}</el-button>
              </div>
              <div class="direct-add-device-wrap" v-if="((!isDirectAddDevice && !terminalFormIsEdit) || (isDirectAddDevice && terminalFormIsEdit)) &&
            terminalFormData.new_device_type_id == CONGMINFU_DEVICE_TYPE_ID">
                <el-form-item label="内插分类:" prop="brandID" label-width="100px">
                  <el-cascader :options="optionsBrand" :props="props" @change="onChangeBrand" filterable />
                </el-form-item>
                <el-form-item label="子分类:" prop="softUid" v-if="terminalFormData.brandID == 'yhc_v5'"
                  label-width="100px">
                  <el-select v-model="terminalFormData.softUid" clearable filterable @change="onChangeSoftUid">
                    <el-option v-for="item in optionsPlugin" :key="item.uuid" :label="item.name"
                      :value="item.uuid"></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="direct-add-device-wrap" v-if="((!isDirectAddDevice && !terminalFormIsEdit) || (isDirectAddDevice && terminalFormIsEdit)) &&
            terminalFormData.new_device_type_id == ALI_RUYI_DEVICE_TYPE_ID">
                <el-form-item label="如意设备品牌:" label-width="100px">
                  <el-select v-model="terminalFormData.ruyi_brand_id" disabled>
                    <el-option v-for="item in optionsRuyiBrand" :key="item.supplier_id" :label="item.name"
                               :value="item.supplier_id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="设备号SN:" prop="out_device_sn" label-width="100px">
                  <el-input v-model="terminalFormData.out_device_sn" placeholder="请输入" style="width: 220px;" />
                </el-form-item>
                <el-form-item label="商户SMID:" prop="" label-width="100px">
                  <el-input v-model="terminalFormData.merchant_smid" placeholder="请输入" style="width: 220px;" />
                </el-form-item>
              </div>
              <div class="direct-add-device-wrap" v-if="((!isDirectAddDevice && !terminalFormIsEdit) || (isDirectAddDevice && terminalFormIsEdit)) &&
            terminalFormData.new_device_type_id == BOSHIJIE_DEVICE_TYPE_ID">
                <el-form-item label="设备号SN:" prop="out_device_sn" label-width="100px">
                  <el-input v-model="terminalFormData.out_device_sn" placeholder="请输入" style="width: 220px;" />
                </el-form-item>
              </div>
            </div>

          </el-form-item>

          <el-form-item label="收款码:" prop="pay_code">
            <div style="overflow: hidden;">
              <ScanQRCode @getResult="handleResult" @clearAll="clearQrCode"
                :xxl-ticket-sn="terminalDetails.xxl_ticket_sn"
                :text-inputed="terminalFormData.qrcode_text || terminalFormData.qrcode_h5_text" v-if="showTerminalForm">
              </ScanQRCode>
            </div>
          </el-form-item>

          <el-form-item prop="xxl_ticket_sn" v-if="isSubsystemXxl && hideXXLTicketSn">
            <template v-slot:label>
              <span class="required-label">休闲乐手牌号:</span>
            </template>
            <el-input v-model="terminalFormData.xxl_ticket_sn" placeholder="请输入" style="width: 220px;" />
          </el-form-item>

          <el-form-item label="播报设备:" style="overflow: hidden;">
            <el-select v-model="terminalFormData.speaker_provider" placeholder="请选择" clearable>
              <el-option label="鑫易付插件" value="XYF"></el-option>
              <el-option label="博实结" value="BSJ"></el-option>
              <el-option label="证通" value="ZT"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :inline="true" style="overflow: hidden;" v-if="terminalFormData.speaker_provider != ''">
            <div class="inside-form-card">
              <el-form-item>
                <template v-slot:label>
                  <span :class="terminalFormData.speaker_provider == 'XYF' ? '' : 'required-label'"
                    style="margin-top: 6px;">设备序列号:</span>
                </template>
                <el-input v-model="terminalFormData.speaker_config_sn"
                  :placeholder="terminalFormData.speaker_provider == 'XYF' ? '如果不填写系统会自动生成' : '必须填写'"
                  style="width: 240px;">
                  <template #suffix>
                    <ScanSNCode @getResult="handleSNCodeResult"></ScanSNCode>
                  </template>
                </el-input>
                <el-button size="small" type="warning" style="margin-left: 10px" :icon="Phone" plain
                  @click="testSpeaker" v-if="terminalFormData.speaker_provider && terminalFormData.speaker_config_sn">
                  测试
                </el-button>
              </el-form-item>
            </div>
          </el-form-item>

          <el-form-item label="是否启用" prop="status">
            <el-switch v-model="terminalFormData.status" active-value="1" inactive-value="0"></el-switch>
          </el-form-item>

        </el-form>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="hideTerminalForm()">取消</el-button>
            <el-button type="primary" @click="terminalFormIsEdit ? updateTerminal() : addNewTerminal(addTerminalRef)"
                       :loading="isSubmitBtnLoading">
              保存提交
            </el-button>
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, getCurrentInstance, watch, onUnmounted, computed } from "vue"
import { Edit, Plus, Search, Menu, Phone } from '@element-plus/icons-vue'
import { ElNotification, ElMessage } from 'element-plus'
import ScanQRCode from '../../components/ScanQRCode/ScanQRCode.vue';
import ScanSNCode from '../../components/ScanQRCode/ScanSNCode.vue';
const { proxy } = getCurrentInstance()

const loading = ref(true)
const tableData = ref([])
const showTerminalForm = ref(false)
const terminalFormIsEdit = ref(false)
const showTerminalDetail = ref(false)
const editingTerminalId = ref('')
const searchForm = ref({
  merchant_id: "",
  store_id: "",
  agent_id: "",
  id: "",
  status: "",
  device_type_id: "",
  active_code: "",
  device_sn: "",
})
const addTerminalRef = ref()
const searchFormRef = ref()
const terminalFormData = reactive({
  name: '',
  device_id: '',
  store_id: '',
  merchant_id: '',
  status: '1',
  new_device_type_id: '',
  categoryName: '',
  pluginPid: '',
  softUid: '',
  brandID: '',
  qrcode_sn: '',
  qrcode_text: '',
  qrcode_h5_text: '',
  speaker_provider: "",
  speaker_config_sn: "",
  out_device_sn: "",
  merchant_smid: "",
  // 目前仅支持：如意Lite 2.0（碰一下设备）
  ruyi_brand_id: "202008061702346558",
  // 编辑表单时，当前终端绑定的设备类型ID
  device_type_id: '',
})

const hideXXLTicketSn = ref(true)
const clearQrCode = () => {
  terminalFormData.qrcode_sn = ''
  terminalFormData.xxl_ticket_sn = ''
  hideXXLTicketSn.value = false
  console.log('clear qr code', terminalFormData)
}

const disableMerchantInput = ref(false)

const terminalRules = ref({
  name: [
    {
      required: true,
      message: '请输入',
      trigger: 'blur'
    }
  ],
  merchant_id: [
    {
      required: true,
      message: '请选择',
      trigger: 'blur'
    }
  ],
  status: [
    {
      required: true,
      message: '状态不能为空',
      trigger: 'change',
    }
  ],
})

const props = {
  expandTrigger: 'hover',
}

const onClickAddTerminal = () => {
  terminalFormData.name = ''
  terminalFormData.merchant_id = ''
  terminalFormData.store_id = ''
  terminalFormData.device_id = ''
  terminalFormData.status = '1'
  terminalFormData.new_device_type_id = ''
  terminalFormIsEdit.value = false
  showTerminalForm.value = true
  isDirectAddDevice.value = false
  disableMerchantInput.value = false
  terminalFormData.qrcode_sn = ''
  terminalFormData.qrcode_text = ''
  terminalFormData.qrcode_h5_text = ''
  terminalFormData.speaker_provider = ''
  terminalFormData.device_type_id = ''
  terminalFormData.out_device_sn = ''
  terminalFormData.merchant_smid = ''
}
const onClickResetSearchField = () => {
  searchForm.value.store_id = ''
  searchForm.value.agent_id = ''
  searchForm.value.merchant_id = ''
  searchForm.value.status = ''
  searchForm.value.id = ''
  searchForm.value.device_type_id = ''
  searchForm.value.active_code = ''
  searchForm.value.device_sn = ''
  console.log(searchForm.value, 'after reset')
  getTerminalList(searchForm.value, currentPage.value)
}

const hideTerminalForm = () => {
  showTerminalForm.value = false
  terminalFormData.xxl_ticket_sn = ''
  terminalFormData.speaker_config_sn = ''
  console.log('on  close dialog', showTerminalForm.value)
}

// 终端-设备列表
const optionsDevice = ref([])
const getOptionsDevice = async (data) => {
  if (!data.merchant_id) {
    return
  }
  await proxy.$api.dict.getDeviceDict(data).then(res => {
    if (res.status == 200) {
      optionsDevice.value = res.data.data
      console.log(optionsDevice.value, data, 'freddy')
    }
  })
}

watch(() => terminalFormData.merchant_id, (newV) => {
  if (disableMerchantInput.value) {
    return
  }
  console.log("watch form merchant id change", newV)
  optionsDevice.value = []
  terminalFormData.device_id = ''
  optionsStore.value = []
  terminalFormData.store_id = ''
  if (newV) {
    getOptionsStore({ merchant_id: terminalFormData.merchant_id })
    getOptionsDevice({ merchant_id: terminalFormData.merchant_id })
  }
});

watch(() => terminalFormData.store_id, (newV) => {
  if (disableMerchantInput.value) {
    return
  }
  console.log("watch form store id change", newV)
  optionsDevice.value = []
  terminalFormData.device_id = ''
  if (newV) {
    getOptionsDevice({
      merchant_id: terminalFormData.merchant_id,
      store_id: terminalFormData.store_id,
    })
  }
});

const optionsMerchant = ref([])
const getOptionsMerchant = async (data) => {
  await proxy.$api.dict.getMerchantDict(data).then(res => {
    if (res.status == 200) {
      optionsMerchant.value = res.data.data
    }
  })
}

const testSpeaker = () => {
  const provider = terminalFormData.speaker_provider
  const config_sn = terminalFormData.speaker_config_sn
  if (!provider || !config_sn) {
    return
  }
  proxy.$api.terminals.testSpeaker({
    provider, config_sn
  }).finally(() => {
    ElMessage({
      message: '已提交播报指令',
      type: 'success',
    })
  })
}

//新增终端
const addNewTerminal = async (ele) => {
  if (!ele) return
  await ele.validate((valid) => {
    console.log(valid);
    if (valid) {
      isSubmitBtnLoading.value = true
      proxy.$api.terminals.addNewTerminal(terminalFormData).then(res => {
        if (res.status == 200) {
          isSubmitBtnLoading.value = false
          showTerminalForm.value = false;
          ElMessage({
            message: '添加成功',
            type: 'success',
          })
          addTerminalRef.value.resetFields()
          getTerminalList(searchForm.value, currentPage.value);
        } else {
          ElMessage({
            message: '终端添加失败,请稍后重试',
            type: 'error',
          })
        }
      }).finally(() => {
        isSubmitBtnLoading.value = false
      })
    }
  })
};

const isSubmitBtnLoading = ref(false)

//更新终端
const updateTerminal = async () => {
  try {
    isSubmitBtnLoading.value = true
    const res = await proxy.$api.terminals.updateTerminal(editingTerminalId.value, terminalFormData);
    if (res.status === 200) {
      ElNotification({
        title: 'Success',
        message: '更新成功',
        type: 'success'
      });
      addTerminalRef.value.resetFields()
      showTerminalForm.value = false;
      isSubmitBtnLoading.value = false
      getTerminalList(searchForm.value, currentPage.value);
    }
  } catch (error) {
    isSubmitBtnLoading.value = false
    // 处理请求失败的情况
    console.error(error);
    ElNotification({
      title: 'Error',
      message: error.response.data.error.message,
      type: 'error'
    });
  }
};

//获取终端详细信息
const terminalDetails = ref({})
const detailParams = ref([])
const detailKeyName = {
  terminal_id: "终端ID",
  name: "终端名称",
  merchant_name: "商户",
  store_name: "门店",
  agent_name: "代理",
  device_type: "设备类型",
  device_sn: "设备序列号",
  device_secret: "设备密钥",
  created_at: "创建时间",
  updated_at: "更新时间",
  active_code: "激活码",
  actived_at: "激活时间",
  status: "启用状态",
  operator: "操作人",
  speaker: "播报设备",
}
const congmingPayParams = ref([])
const congmingPayParamsKeyName = {
  categoryName: '设备分类',
  yhc_secret_key: '插件密钥',
  shop_id: '商户ID',
  sn: '编码SN',
  short_key: 'short_key',
  brandID: 'brandID',
  softUid: 'softUid',
}
const ruyiParams = ref([])
const ruyiParamsKeyName = {
  out_device_sn: '设备号SN',
  merchant_smid: '商户SMID',
}
const bsjParams = ref([])
const bsjParamsKeyName = {
  out_device_sn: '设备号SN',
}

const wrapperSuffix = (content) => {
  return " (" + content + ")"
}
const onClickShowTerminal = async (row) => {
  // 组装终端基本信息
  terminalDetails.value = row
  console.log(terminalDetails.value);
  const {
    merchant, name, status, device, agent, store, id,
    actived_at, created_at, updated_at, operator, active_code,
    speaker
  } = row;
  const detail = {
    terminal_id: id,
    name: name,
    device_sn: device?.serial_number || '-',
    device_secret: device?.secret || '-',
    device_type: device ? ((device?.device_category?.name || '-') + '/' + (device?.device_type?.name || '-')) : '-',
    merchant_name: merchant ? merchant.merchant_alias + wrapperSuffix(merchant.no) : '-',
    store_name: store ? store.name + wrapperSuffix(store.id) : '-',
    agent_name: agent ? agent.name + wrapperSuffix(agent.id) : '-',
    actived_at: actived_at || '-',
    created_at,
    updated_at,
    operator,
    status,
    active_code,
    speaker: speaker ? (speaker?.provider_name + ' / ' + speaker.config_sn) : '-'
  }
  let newDetailParams = []
  for (const key in detailKeyName) {
    newDetailParams.push({
      label: detailKeyName[key],
      value: detail[key] || ''
    })
  }
  detailParams.value = newDetailParams

  // 组装插件参数信息
  const congmingPayInfo = device?.data?.congmingpay_device_info
  if (congmingPayInfo) {
    let newParams = []
    for (const congmingPayInfoKey in congmingPayInfo) {
      if (!congmingPayParamsKeyName[congmingPayInfoKey]) {
        continue
      }
      newParams.push({
        label: congmingPayParamsKeyName[congmingPayInfoKey] || congmingPayInfoKey,
        value: congmingPayInfo[congmingPayInfoKey]
      })
    }
    congmingPayParams.value = newParams
  }

  // 组装如意参数信息
  const aliruyipayInfo = device?.data?.aliruyipay_device_info
  if (aliruyipayInfo) {
    let newParams = []
    for (const aliruyipayInfoKey in aliruyipayInfo) {
      if (!ruyiParamsKeyName[aliruyipayInfoKey]) {
        continue
      }
      newParams.push({
        label: ruyiParamsKeyName[aliruyipayInfoKey] || aliruyipayInfoKey,
        value: aliruyipayInfo[aliruyipayInfoKey]
      })
    }
    ruyiParams.value = newParams
  }

  // 组装博实结参数信息
  const bsjInfo = device?.data?.boshijie_device_info
  if (bsjInfo) {
    let newParams = []
    for (const bsjInfoKey in bsjInfo) {
      if (!bsjParamsKeyName[bsjInfoKey]) {
        continue
      }
      newParams.push({
        label: bsjParamsKeyName[bsjInfoKey] || bsjInfoKey,
        value: bsjInfo[bsjInfoKey]
      })
    }
    bsjParams.value = newParams
  }

  showTerminalDetail.value = true
}

//分页
const pagination = ref({
  total: 0,
  perPage: 0,
})

//当前页
const currentPage = ref(1)

//下一页
const handleSizeChange = (val) => {
  currentPage.value = val
  console.log(searchForm);
  getTerminalList(searchForm.value, currentPage.value)
}

//表格数据
const getTerminalList = async (data, page) => {
  loading.value = true
  if (page == "1") {
    searchForm.value.page = 1
    pagination.value.total = 1
    await proxy.$api.terminals.getTerminalList(data).then(res => {
      if (res.status == 200) {
        tableData.value = res.data.data
        pagination.value = res.data.pagination
      }
      loading.value = false
    })
  } else {
    data.page = page
    await proxy.$api.terminals.getTerminalList(data).then(res => {
      if (res.status == 200) {
        tableData.value = res.data.data
        pagination.value = res.data.pagination
      }
      loading.value = false
    })
  }
}

const onClickEditTerminal = (row) => {
  const { merchant, name, status, device, store, id } = row;

  editingTerminalId.value = id
  terminalFormData.name = name
  terminalFormData.merchant_id = merchant?.id || ''
  terminalFormData.store_id = store?.id || ''
  terminalFormData.device_id = device?.id || ''
  terminalFormData.status = status ? '1' : '0'
  terminalFormData.new_device_type_id = ''
  terminalFormData.device_type_id = device?.device_type?.id || ''
  if (terminalFormData.merchant_id) {
    disableMerchantInput.value = true
  } else {
    disableMerchantInput.value = false
  }
  terminalFormData.qrcode_sn = row.code?.identifying_code || ''
  terminalFormData.qrcode_text = row.code?.url_v2 || ''
  terminalFormData.qrcode_h5_text = row.code?.url_h5 || ''
  parseQrcodeURL.value = terminalFormData.qrcode_h5_text
  terminalFormData.xxl_ticket_sn = row.xxl_ticket_sn || ''
  terminalFormData.speaker_provider = row.speaker?.provider || ''
  terminalFormData.speaker_config_sn = row.speaker?.config_sn || ''
  terminalFormData.out_device_sn = ''
  terminalFormData.merchant_smid = ''

  getOptionsStore({
    merchant_id: terminalFormData.merchant_id,
  })
  getOptionsDevice({
    merchant_id: terminalFormData.merchant_id,
    store_id: terminalFormData.store_id,
  })

  console.log(JSON.stringify(terminalFormData), "load form value")
  terminalFormIsEdit.value = true
  showTerminalForm.value = true;
  isDirectAddDevice.value = false
}
const isDirectAddDevice = ref(false)
const switchDeviceAddMode = () => {
  isDirectAddDevice.value = !isDirectAddDevice.value
  if (isDirectAddDevice.value) {
    terminalFormData.device_id = ''
  } else {
    // 清空【直接添加设备】表单
    terminalFormData.new_device_type_id = ''
    terminalFormData.brandID = ''
    terminalFormData.pluginPid = ''
    terminalFormData.softUid = ''
    terminalFormData.categoryName = ''
  }

  return
}

let deviceOptions = reactive([])
const queryDeviceTypes = async () => {
  await proxy.$api.agents.queryDeviceCategories().then(res => {
    if (res.status == 200) {
      res.data.data.forEach(item => {
        if (item.device_types.length > 0) {
          let new_ary = { ...item }
          new_ary["value"] = item.id
          new_ary["label"] = item.name
          new_ary["children"] = item.device_types.map((citem) => {
            citem["value"] = citem.id
            citem["label"] = citem.name
            return citem
          })
          deviceOptions.push(new_ary)
        }
      })
    }
  })
}

const CONGMINFU_DEVICE_TYPE_ID = 11
const ALI_RUYI_DEVICE_TYPE_ID = 13
const BOSHIJIE_DEVICE_TYPE_ID = 14
const onChangeDeviceType = (val) => {
  terminalFormData.new_device_type_id = val[1]
  terminalFormData.brandID = ''
  terminalFormData.pluginPid = ''
  terminalFormData.softUid = ''
  terminalFormData.categoryName = ''
  if (terminalFormData.new_device_type_id == CONGMINFU_DEVICE_TYPE_ID) {
    getCongMingPayCategory()
  }
}

let optionsBrand = ref([])
let optionsPlugin = ref([])

const optionsRuyiBrand = ref([
  {
    name: '如意Lite',
    supplier_id: '201901111100635561',
    sn_prefix: 'FZ'
  },
  {
    name: '如意Lite 升级款',
    supplier_id: '202109071403204809',
    sn_prefix: 'MG'
  },
  {
    name: '如意Lite 2.0（碰一下设备）',
    supplier_id: '202008061702346558',
    sn_prefix: 'LD'
  }
])
const onChangeBrand = (val) => {
  terminalFormData.brandID = val[0]
  terminalFormData.pluginPid = val[1]
  terminalFormData.softUid = ''
  terminalFormData.categoryName = ''
  if (val[1]) {
    getCongMingPayPluginChildren(val[1])
  }
  let selectItem = optionsBrand.value.find(item => item.value == val[0])
  let categoryName = selectItem.label
  if (val[1]) {
    categoryName += '/' + selectItem.children.find(item => item.value == val[1]).label
  }
  terminalFormData.categoryName = categoryName
}

const getCongMingPayPluginChildren = async (pid) => {
  await proxy.$api.thirdPartyDevice.congMingPayPluginChildren({ pid }).then(r => {
    if (r.status == 200) {
      optionsPlugin.value = r.data.data
    }
  })
}

const getCongMingPayCategory = async () => {
  await proxy.$api.thirdPartyDevice.congMingPayCategory().then(r => {
    if (r.status == 200) {
      optionsBrand.value = r.data.data
    }
  })
}

const onChangeSoftUid = (val) => {
  let selectItem = optionsPlugin.value.find(item => item.uuid == val)
  terminalFormData.categoryName += '/' + selectItem.name
  terminalFormData.softUid = val
  console.log(terminalFormData.categoryName, terminalFormData.softUid, 'on change soft uid')
}

const optionsStore = ref([])
const getOptionsStore = async (data) => {
  await proxy.$api.dict.getStoreDict(data).then(res => {
    if (res.status == 200) {
      optionsStore.value = res.data.data
    }
  })
}
const parseQrcodeURL = ref('')
const isSubsystemXxl = computed(() => {
  if (!parseQrcodeURL.value) {
    return false
  }
  return parseQrcodeURL.value.indexOf('xxl') > -1
})
const handleResult = (result) => {
  const url = result
  parseQrcodeURL.value = url
  const urlParams = new URLSearchParams(new URL(url).search);
  const identifyingCode = urlParams.get('identifying_code');
  console.log("qr result", result, identifyingCode);
  terminalFormData.qrcode_sn = identifyingCode || null
  hideXXLTicketSn.value = true
};


//识别SN码
const handleSNCodeResult = (result) => {
  terminalFormData.speaker_config_sn = result
}

onUnmounted(() => {
  console.log('unmounted terminal');
});

onMounted(() => {
  console.log('on mounted terminal')
  getTerminalList(searchForm.value, currentPage.value)
  getOptionsMerchant()
  queryDeviceTypes()
})

</script>


<style lang="less" scoped>
.container {
  width: 100%;
  box-sizing: border-box;

  .box-card {
    border-top: 1px solid #e7e7e7;
  }

  .card-header {
    span {
      font-size: 15px;
      font-weight: bold;
    }
  }
}

.params-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 5px;
  margin-top: 10px;

  .third-party-device-params {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
}

.main {
  .card-border {
    margin-top: 8px;
    border-top: 1px solid #e7e7e7;
    width: 100%;
  }

  .addNewTerminal {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
  }

  .pagination {
    margin-top: 20px;
  }

  .direct-add-device-wrap {
    margin-top: 10px;
    border: 1px solid #cccccc;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-start;
    align-items: center;
  }

  .inside-form-card {
    margin-top: 10px;
    border: 1px solid #cccccc;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-start;
    align-items: center;
  }

  .detail-rows {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    font-size: 15px;

    .detail-item {
      display: flex;
      align-items: center;

      .label {
        width: 90px;
        height: 30px;
        text-align: center;
        margin-right: 20px;
        font-size: 13px;
      }

      .value {
        flex: 1;
        line-height: 30px;
      }
    }
  }
}

.qrcode-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
